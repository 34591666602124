
  .row.btnsa{
    position: relative;
    /* top: -2rem; */
  }
  #bu{
    position: relative;
    top: 1.9rem;
  }
  #paylater{
    position: relative;
    top: 1.9rem;
    
  }
  .calculated_price_heading {
      /* border-radius: 18px 18px 0 0; */
      background-image: linear-gradient(120deg, #f5a40d 0%, #d4e3d4 100%);
      border-radius: 0.2rem;
      padding: 15px 18px;
      /* width: 10rem; */
      
  }
  #c{
    background-color: white;
    /* box-shadow: #1e293b; */
    /* border: 1px solid ; */
  }
  .layout_tabs_mains{
  
  
  /* box-shadow: 0px 0px 3px #0000007d; */
  }
  
  .cart-button{
    background-color:orange;
    border:none ;
    padding-left: 4rem;
    padding-right: 4rem;
    height: 3rem;
    font-size: large;
    font-weight: bold;
    position: relative;
    bottom: 1rem;
    top: 1rem;
    color: black;
  
    transition: 500ms linear; 
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px; */
    
    
  }
  .cart-button:hover{
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
  }
  .cart-button:active{
    background-color:white;
    transform: skewX(-10);
    /* box-shadow: none; */
  }
  
  
  .cart-buttons{
    background-color:orange;
    border:none ;
    font-size: large;
    font-weight: bold;
  
    padding-left: 4rem;
    padding-right: 4rem;
    height: 3rem;
    position: relative;
    top:1.3rem;
    /* bottom: 1rem; */
    color: black;
    transition: 500ms linear; 
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px; */
    
    
  }
  .cart-buttons:hover{
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
  }
  .cart-buttons:active{
    background-color:white;
    transform: skewX(-10);
    /* box-shadow: none; */
  }
  .table-borderless td,
  .table-borderless th {
      border: 0;
  }
  
  
  
 .images{
  width: 20px;
  position: relative;
  left: 23rem;
 }
  
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    display: flex;
    align-items: center;
     /* padding: 16px 20px 16px 15px; */
   /* height: 56px;  */
    position: relative;
    font-family: "Roboto", sans-serif !important;
    font-size: 1em;
    line-height: 1em;
    font-weight: 500;
    background-color: white;
    cursor: pointer;
    /* border-radius: 32px; */
    /* box-shadow: 0 8px 32px rgba(0,0,0,0.25); */
    -webkit-tap-highlight-color: transparent;
    /* transition: box-shadow 0.2s ease, background-color 150ms ease; */
    &:focus {
      /* box-shadow: 0 8px 64px 16px rgba(0,0,0,0.5); */
      outline: none;
    }
    
    &:hover, &:active {
      background-color: #eee;
    }
  }

  @keyframes slide-up-fade {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-16px);
      opacity: 0;
    }
  }
  
  @keyframes roll-out {
    0% {
      transform: translate(0) rotate(0);
    }
    20% {
      transform: translate(0) rotate(-70deg);
      opacity: 1;
    }
    50% {
      transform: translate(0) rotate(-45deg);
      opacity: 1;
    }
    100% {
      transform: translate(140px) rotate(-47deg);
      opacity: 0;
    }
  }
  
  .checked-out span {
    animation: slide-up-fade 150ms 1;
    animation-fill-mode: both;
  }
  
  .checked-out #cart {
    animation: roll-out 1s 1 150ms;
    animation-timing-function: ease-in;
    animation-fill-mode: both;
  }
  
  @keyframes checkmark {
    from {
      stroke-dashoffset: 26px;
    }
    to {
      stroke-dashoffset: 0;  
    }
  }
  
  #check {
    position: absolute;
    left: calc(50% - 12px);
  }
  
  #check path {
    stroke-dasharray: 26px;
    stroke-dashoffset: 26px;
  }
  
  .checked-out #check path {
    animation: checkmark 150ms 1 1150ms;
    animation-timing-function: ease-in;
    animation-fill-mode: both;
  }
  
  .checked-out button {
    background-color: #20bf6b;
    transition-delay: 1150ms;
  }
  
  
  
  
  
  #btnsa {
    width: 180px;
    height: 60px;
    font-weight: bold;
    cursor: pointer;
    background: transparent;
    border: 1px solid #91C9FF;
    outline: none;
    
    background-color: #f5a40d;
  
    transition: 1s ease-in-out;
  }
  
 
  
  #btnsa:hover {
    transition: 1s ease-in-out;
    background: #f5a40d;
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
  
  }
  
  #btnsa:hover svg {
    stroke-dashoffset: -480;
  }
  
  #btnsa span {
    color: black;
    font-size: 18px;
    font-weight: bolder;
  }
  
  
  /* CSS */
  .button-81 {
    background-color: #fff;
    border: 2px solid #e2e8f0;
    /* border-radius: 1.5rem; */
    /* box-sizing: border-box; */
    color: #0d172a;
    cursor: pointer;
    display: inline-block;
    font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1;
    padding: 1rem 1.6rem;
    text-align: center;
    text-decoration: none #0d172a solid;
    text-decoration-thickness: auto;
    transition: all 0.7s cubic-bezier(.4, 0, .2, 1);
    /* box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25); */
  }
  
  .button-81:hover {
    background-color: #1e293b;
    color: #fff;
  }
  .input1 {
   
    margin-top: 5px;
    text-indent: 5px;
    font-size: 12.6pt;
    padding: 0.4rem;
    border-radius: 5px;
    border: solid 1.5px #D3D3D3;
    -webkit-transition: 1s; /* Safari */
    transition: 1s;
  }
  .input1[type=text]:hover{
    box-shadow: 0 0 5pt 0.5pt #D3D3D3;
  }
  .input[type=text]:focus {
    box-shadow: 0 0 5pt 2pt #D3D3D3;
    outline-width: 0px;
  }
  
  @media screen and (max-width:500px) {
    .images{
      width: 20px;
      position: relative;
      left: 18rem;
     }
      
    
  }