.carousel-control-prev:hover {
    opacity: 0.7; /* Reduce opacity on hover */
    background-color: rgba(255, 255, 255, 0) !important;
    /* width: 50%; */
  }

  .carousel-control-next:hover {
    opacity: 0.7; /* Reduce opacity on hover */
    background-color: rgba(255, 255, 255, 0) !important;
    /* width: 50%; */
  }