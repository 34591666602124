.cart-button{
    background-color:orange;
    border:none ;
    padding-left: 4rem;
    padding-right: 4rem;
    height: 3rem;
    font-size: large;
    font-weight: bold;
    position: relative;
    bottom: 1rem;
    color: black;
  
    transition: 500ms linear; 
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    
    
  }
  .cart-button:hover{
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
  }
  .cart-button:active{
    background-color:white;
    transform: skewX(-10);
    box-shadow: none;
  }
  
  
  .cart-buttons{
    background-color:orange;
    border:none ;
    font-size: large;
    font-weight: bold;
  
    padding-left: 4rem;
    padding-right: 4rem;
    height: 3rem;
    position: relative;
    top:1.3rem;
    /* bottom: 1rem; */
    color: black;
    transition: 500ms linear; 
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    
    
  }
  .cart-buttons:hover{
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
  }
  .cart-buttons:active{
    background-color:white;
    transform: skewX(-10);
    box-shadow: none;
  }
  .table-borderless td,
  .table-borderless th {
      border: 0;
  }